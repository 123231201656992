import React from "react";
import styled from "@emotion/styled";
import { colors } from "../utilities/themeVars";
import PropTypes from "prop-types";
import ButtonGrid from "./ButtonGrid";

const Container = styled.div`
  display: inline-block;
  border: solid 2px ${colors.primary};
`;

const BinaryGridToggle = ({ isGrid, handler }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <Container>
        <ButtonGrid active={!isGrid ? 1 : 0} onClick={() => handler()}>
          List
        </ButtonGrid>
        <ButtonGrid active={isGrid ? 1 : 0} onClick={() => handler()}>
          Grid
        </ButtonGrid>
      </Container>
    </div>
  );
};

BinaryGridToggle.propTypes = {
  isGrid: PropTypes.bool,
  handler: PropTypes.func,
};

export default BinaryGridToggle;
