import React, { useContext } from "react";
import { graphql } from "gatsby";
import BinaryGridToggle from "../components/BinaryGridToggle";
import LinkToTour from "../components/LinkToTour";
import styled from "@emotion/styled";
import HeadingWithSub from "../components/HeadingWithSub";
import { StoreContext } from "../store";
import { replacedTermName } from "../utilities/helpers";
import Seo from "../components/seo";
import GalleryContainer from "../components/GalleryContainer";
import ParentRouteHandler from "../components/ParentRouteHandler";

const Container = styled.div`
  .heading {
    margin-bottom: 30px;
  }

  .toggle-wrap {
    margin-bottom: 20px;
  }
`;

const TourTermTemplate = (props) => {
  const { location } = props;
  const { taxonomyTermTours: tour_term, allNodeTourStop } = props.data;
  const { isGrid, toggleToursView } = useContext(StoreContext);

  const navigatedFromFamiliesPage = location.search.includes("families=1");

  const navigatedFromHome =
    location.state && location.state.originalPath === "home";

  let parentRoute = "/audio";
  let parentTitle = "Audio Tours";

  if (navigatedFromFamiliesPage) {
    parentRoute = "/for-families";
    parentTitle = "For Families";
  } else if (navigatedFromHome) {
    parentRoute = "/";
    parentTitle = "Home";
  }

  const processedForGallery = props.data.allNodeTourStop.edges.map(
    ({ node }) => {
      let nodeImage = null;
      if (
        (node.rel.field_related_artwork && node.rel.field_related_artwork[0]) ||
        (node.rel.tour_image && node.rel.tour_image[0])
      ) {
        if (
          node.rel.field_related_artwork[0] &&
          typeof node.rel.field_related_artwork[0] !== "undefined"
        ) {
          nodeImage =
            node.rel.field_related_artwork[0].rel.image?.rel.media.localFile;
        } else {
          if (node.rel.tour_image[0].localFile) {
            nodeImage = node.rel.tour_image[0].localFile;
          }
        }
      }

      return {
        image: nodeImage,
        node,
      };
    }
  );

  const renderList = (tours) => {
    return (
      <div>
        {tours
          .sort(
            (a, b) =>
              a.node.field_sort_order_weight - b.node.field_sort_order_weight
          )
          .map(({ node: tour_stop }, idx) => {
            let image;
            if (
              !tour_stop.rel.field_related_artwork[0] &&
              tour_stop.rel.tour_image[0] &&
              tour_stop.rel.tour_image[0].localFile
            ) {
              image = tour_stop.rel.tour_image[0].localFile;
            } else if (
              tour_stop.rel.field_related_artwork &&
              tour_stop.rel.field_related_artwork[0]
            ) {
              image =
                tour_stop.rel.field_related_artwork[0].rel.image?.rel.media
                  .localFile;
            }

            const tourLink = navigatedFromFamiliesPage
              ? `${tour_stop.path.alias}?families=1`
              : tour_stop.path.alias;

            return (
              tour_stop.path && (
                <LinkToTour
                  key={idx}
                  title={tour_stop.title}
                  link={tourLink}
                  image={image}
                />
              )
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <Seo title={replacedTermName(tour_term.name)} />
      <ParentRouteHandler title={parentTitle} path={parentRoute} />

      <Container>
        <div className={`heading`}>
          <HeadingWithSub
            subTitle={replacedTermName(tour_term.name)}
            main={tour_term.field_mobile_display_name}
          />
        </div>

        <div className={`toggle-wrap`}>
          <BinaryGridToggle isGrid={isGrid} handler={() => toggleToursView()} />
        </div>

        {isGrid ? (
          <GalleryContainer
            data={processedForGallery}
            queryParams={navigatedFromFamiliesPage && "families=1"}
          />
        ) : (
          renderList(props.data.allNodeTourStop.edges)
        )}
      </Container>
    </div>
  );
};

export default TourTermTemplate;

export const query = graphql`
  query tourTerm($drupal_id: String!) {
    taxonomyTermTours(drupal_id: { eq: $drupal_id }) {
      name
      field_mobile_display_name
    }
    allNodeTourStop(
      sort: { fields: [field_sort_order_weight], order: ASC }
      filter: {
        relationships: {
          field_related_tour: { elemMatch: { drupal_id: { eq: $drupal_id } } }
        }
      }
    ) {
      edges {
        node {
          title
          field_sort_order_weight
          path {
            alias
          }
          rel: relationships {
            tour_image: field_light_box_image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            field_related_artwork {
              rel: relationships {
                image: field_preview_sharing_image_med {
                  rel: relationships {
                    media: field_media_image {
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
